import { concat } from "lodash";
import {
  getAPIFilters,
  getValueFilters,
  letFiltersRestore,
  useMetaFilters,
  UseMetaFiltersReturn
} from "PFApp/use_filtered_collection";
import { getMatchDisplayValue } from "PFCore/helpers/match_value";
import { useMatchesInfinite } from "PFCore/hooks/queries/matches/use_matches";
import { Booking, Filters, Meta, OrderMeta, OrderOption, Profile, QueryParams, Value } from "PFTypes";
import { useState } from "react";

const PER_PAGE = 10;

const formatScore = (score: number | null | undefined) => getMatchDisplayValue(score, { digits: 0 });

export type MatchesTableEntry = {
  profile: Profile;
  matchScore: string | undefined;
  availabilityScore: string | undefined;
};

export type MatchesResponse = {
  matchesMeta: Meta;
  matchesData: MatchesTableEntry[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  lastRefreshAt: string | null;
};

type RestoreFilters = {
  restoreAllFilters: () => void;
  letRestoreAll: boolean;
};

type MatchesOrder = {
  orderMeta: OrderMeta | undefined;
  handleChangeOrder: (value: OrderOption) => void;
};

type UseMatches = {
  booking: Booking;
};

type UseMatchesReturn = {
  matchesResponse: MatchesResponse;
  matchesFilters: QueryParams;
  restoreFilters: RestoreFilters;
  matchesOrder: MatchesOrder;
  updateFilter: UseMetaFiltersReturn["updateFilter"];
};

export const useReassignMatches = ({ booking }: UseMatches): UseMatchesReturn => {
  const [matchesFilters, setMatchesFilters] = useState<QueryParams>({
    perPage: PER_PAGE
  });

  const updateMatchesParams = (params: QueryParams) =>
    setMatchesFilters((currentParams) => ({
      ...currentParams,
      ...params
    }));

  const {
    data: matchesPaginatedData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useMatchesInfinite(booking.activity_id ?? -1, matchesFilters, {
    keepPreviousData: true,
    cacheTime: 0
  });

  const mergedPages = concat(...(matchesPaginatedData?.pages.map(({ entries }) => entries) || []));

  const meta =
    matchesPaginatedData && matchesPaginatedData.pages.length > 0
      ? matchesPaginatedData?.pages[0].meta
      : ({} as Meta);

  const lastRefreshAt =
    meta && meta.timestamps && "matched_at" in meta.timestamps ? meta.timestamps.matched_at : null;

  const matchesData = mergedPages
    .filter(({ profile }) => profile.id !== booking.profile_id)
    .map(({ profile, scores }) => ({
      profile,
      matchScore: formatScore(scores.normalized_score),
      availabilityScore: formatScore(scores.availability_score)
    }));

  const orderMeta = meta?.order;

  const updateFilterValues = (updatedFilters: Filters<Value>) => {
    updateMatchesParams({ filters: getAPIFilters(updatedFilters) });
  };

  const { resetFilters, updateFilter } = useMetaFilters(meta, updateFilterValues);

  const restoreAllFilters = () => {
    resetFilters(meta.default_filters);
  };

  const letRestoreAll = letFiltersRestore(getValueFilters(meta.filters), meta.default_filters);

  const handleChangeOrder = (value: OrderOption) => updateMatchesParams({ order: value });

  const matchesResponse = {
    matchesMeta: meta,
    matchesData,
    fetchNextPage,
    hasNextPage: !!hasNextPage,
    isFetching,
    isFetchingNextPage,
    lastRefreshAt
  };

  const restoreFilters = {
    restoreAllFilters,
    letRestoreAll
  };

  const matchesOrder = {
    orderMeta,
    handleChangeOrder
  };

  return {
    matchesResponse,
    matchesFilters,
    restoreFilters,
    matchesOrder,
    updateFilter
  };
};
